'use client'

import { createContext, useState, ReactNode, useContext } from 'react'

// Extend the context type to include startTour, loaded and their setters
const AppStateContext = createContext<
  | {
      isGenerating: boolean
      setIsGenerating: (value: boolean) => void
      autoScrollMode: boolean
      setAutoScrollMode: (value: boolean) => void
      isMessagesEmpty: boolean
      setIsMessagesEmpty: (value: boolean) => void
      startTour: boolean
      setStartTour: (value: boolean) => void
      loaded: boolean
      setLoaded: (value: boolean) => void
      isChatActivated: boolean;
      setIsChatActivated: (value: boolean) => void;
    }
  | undefined
>(undefined)

export const AppStateProvider = ({ children }: { children: ReactNode }) => {
  const [isGenerating, setIsGenerating] = useState(false)
  const [autoScrollMode, setAutoScrollMode] = useState(true) // Initialize autoScrollMode to true
  const [isMessagesEmpty, setIsMessagesEmpty] = useState(true)
  const [startTour, setStartTour] = useState(true) // Initialize startTour to true
  const [loaded, setLoaded] = useState(false) // Initialize loaded to false
  const [isChatActivated, setIsChatActivated] = useState(false)
  
  return (
    <AppStateContext.Provider
      value={{
        isGenerating,
        setIsGenerating,
        autoScrollMode,
        setAutoScrollMode,
        isMessagesEmpty,
        setIsMessagesEmpty,
        startTour,
        setStartTour,
        loaded,
        setLoaded,
        isChatActivated,
        setIsChatActivated
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}

export const useAppState = () => {
  const context = useContext(AppStateContext)
  if (!context) {
    throw new Error('useAppState must be used within an AppStateProvider')
  }
  return context
}
