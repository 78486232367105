import React, { ReactNode } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
// import { XIcon } from '@heroicons/react/solid'; // Uncomment if using an icon

interface ModalProps {
  isOpen: boolean;
  onOpenChange?: (open: boolean) => void;
  title?: string;
  description?: string;
  width?: number;
  children: ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onOpenChange,
  title,
  description,
  width,
  children,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent
        className={`
          ${width ? `max-w-[${width}px]` : 'sm:max-w-lg'} 
          w-full 
          mx-2s 
          my-4s 
          bg-white 
          rounded-lg 
          shadow-lg 
          overflow-auto 
          max-h-[90vh]
          p-4
          sm:p-6
          md:p-8
        `}
      >
        <DialogHeader className="flex justify-between items-start">
          <div>
            <DialogTitle className="text-xl sm:text-2xl font-semibold">
              {title}
            </DialogTitle>
            {description && (
              <DialogDescription className="mt-2 text-sm sm:text-base text-gray-600">
                {description}
              </DialogDescription>
            )}
          </div>
          {/* Optional Close Button */}
          {/* 
          <Button
            variant="ghost"
            onClick={() => onOpenChange && onOpenChange(false)}
            className="ml-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <XIcon className="h-5 w-5" />
          </Button>
          */}
        </DialogHeader>
        <div className="mt-4">{children}</div>
      </DialogContent>
    </Dialog>
  );
};
